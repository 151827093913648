import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { InvoiceHeader } from './types';
import {
  setHasViewClicked,
  setInvoiceBody,
  setInvoiceHeader,
  setInvoiceNumber,
  setInvoicePDF,
  setIsGeneratingInvoice,
  setOpenInvoicePreview,
} from '../../redux/slices/feesSlice';
import { format } from 'date-fns';
import store from '../../redux/store';

export const createInvoicePdf = async (itemRefs: any, reimbursableAttachment: string[]) => {
  const pdf = new jsPDF('p', 'pt', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  for (let i = 0; i < itemRefs.current.length; i++) {
    const page = itemRefs.current[i];
    const canvas = await html2canvas(page);
    const imgData = canvas.toDataURL('image/png');
    if (i > 0) {
      pdf.addPage();
    }

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  }

  //at this point the invoice is ready - below, we are merging the reimbursible
  if (reimbursableAttachment && reimbursableAttachment.length > 0) {
    const attachments = await mergePDFs(reimbursableAttachment);
    const mergedPdf = await mergeJsPDFandBase64(pdf, attachments);
    return mergedPdf;
  }
  return pdf.output('datauristring');
};

const mergeJsPDFandBase64 = async (pdf, reimbursable) => {
  const existingPdfBytes = pdf.output('arraybuffer');
  const binaryString = atob(reimbursable);

  // Create an ArrayBuffer from the binary string
  const newPdfBytes = new ArrayBuffer(binaryString.length);
  const view = new Uint8Array(newPdfBytes);

  for (let i = 0; i < binaryString.length; i++) {
    view[i] = binaryString.charCodeAt(i);
  }

  const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
  const newPdfDoc = await PDFDocument.load(newPdfBytes);
  const numberOfPages = newPdfDoc.getPageCount();
  for (let i = 0; i < numberOfPages; i++) {
    // Create a new document for the current page
    const singlePageDoc = await PDFDocument.create();

    // Copy the current page to the new document
    const [copiedPage] = await singlePageDoc.copyPages(newPdfDoc, [i]);
    singlePageDoc.addPage(copiedPage);

    // Get the bytes of the single-page document
    const singlePageBytes = await singlePageDoc.save();

    // Load the single-page document
    const pageDoc = await PDFDocument.load(singlePageBytes);

    // Copy the page from the single-page document to the existing document
    const [newPage] = await existingPdfDoc.copyPages(pageDoc, [0]);
    existingPdfDoc.addPage(newPage);
  }

  return await existingPdfDoc.saveAsBase64();
};

export const setInvoiceData = (data, dispatch) => {
  const { selectedFranchise, franchise } = store.getState().userAuth.info;
  const { selectedProjectInvoice } = store.getState().fees;
  const _franchiseId = selectedFranchise || selectedProjectInvoice.franchiseId;
  const _fran = franchise.find(f => f.id === _franchiseId);
  const header: InvoiceHeader = {
    invoiceNumber: data.nextInvoiceNumber || data.invoiceNumber,
    location: data.location,
    projectManager: data.projectManagerName,
    projectName: data.projectName,
    permitName: data.permitName,
    permitNumber: data.permitNumber,
    companyName: data.companyName,
    date: format(new Date(data.date), 'yyyy-MM-dd'),
    dueDate: format(new Date(data.dueDate), 'yyyy-MM-dd'),
    paidDate: data.paidAt ? format(new Date(data.paidAt), 'yyyy-MM-dd') : undefined,
    to: data.sendToName,
    projectAddress: data.projectAddress,
    franchiseAddress: _fran.address,
    franchisePhone: _fran.phoneNumber,
    franchiseName: _fran.name,
  };

  const invoiceBody = createInvoiceBody(data.feeDetails);
  dispatch(setInvoiceHeader(header));
  dispatch(setInvoiceBody(invoiceBody));
  dispatch(setInvoiceNumber(data.nextInvoiceNumber || data.invoiceNumber));
};

export const createInvoiceBody = (fees) => {
  return [
    {
      lineItems: fees.reduce((itemsArr, item) => {
        itemsArr.push({ ...item, name: item.description });
        if (item.comments) {
          item.comments.forEach(comment => {
            const commArr = comment.split('partially paid the fee by applying check number');
            const commItems: string[] = commArr[1].replace(/\$/, '').split('for the amount of');
            const commAmount = Number(commItems[1].substring(0, commItems[1].length - 1).trim()) * -1;
            itemsArr.push({ name: `Memo: ${commItems[0].trim()}`, amount: commAmount, total: commAmount, quantity: 1 });
          });
        }

        return itemsArr;
      }, []),
    },
  ];
};
export const viewInvoice = async (invoicePdf, invoiceNumber, hasViewClicked, dispatch) => {
  if (hasViewClicked) {
    dispatch(setOpenInvoicePreview(true));
  }
};

//try to merge only base64 string
const mergePDFs = async base64PDFs => {
  // Create a new PDFDocument
  const mergedPdf = await PDFDocument.create();

  // Loop through each base64 PDF string
  for (const base64PDF of base64PDFs) {
    // Convert base64 to Uint8Array
    const pdfBytes = Uint8Array.from(atob(base64PDF), c => c.charCodeAt(0));

    // Load the PDF document
    const pdf = await PDFDocument.load(pdfBytes);

    // Copy all pages from the current PDF to the merged PDF
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach(page => mergedPdf.addPage(page));
  }

  // Save the merged PDF as a Uint8Array
  const mergedPdfBytes = await mergedPdf.save();

  // Convert the Uint8Array to base64
  const mergedBase64 = arrayBufferToBase64(mergedPdfBytes); // btoa(String.fromCharCode.apply(null, mergedPdfBytes));

  return mergedBase64;
};

const arrayBufferToBase64 = buffer => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const generatePDFCalculateSplit = async (headerRef, header2Ref, tableRef) => {
  const scale = 0.8;
  const canvArr = [];
  canvArr.push(
    await html2canvas(headerRef.current, {
      scale, // Increase scale for better resolution
    }),
  );
  canvArr.push(
    await html2canvas(header2Ref.current, {
      scale, // Increase scale for better resolution
    }),
  );

  for (var i = 0; i < tableRef.current.length; i++) {
    const t = tableRef.current[i];
    if (t) {
      const can = await html2canvas(t, {
        scale, // Increase scale for better resolution
      });
      canvArr.push(can);
    }
  }

  const doc = new jsPDF('p', 'pt', 'a4');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 30;
  let yOffset = margin;

  canvArr.forEach((canvas, index) => {
    if (true) {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (yOffset + imgHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }

      doc.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight, `img${index}`, 'FAST');
      yOffset += imgHeight;
    }
  });

  const pdfBlob = doc.output('blob');
  return new File([pdfBlob], 'CorrectionLetter.pdf', { type: 'application/pdf' });
};

export const generatePDFAutoPageSplit = async content => {
  const doc = new jsPDF('p', 'pt', 'a4');
  // Capture the content as canvas
  const canvas = await html2canvas(content, {
    scale: 2, // Increase scale for better resolution
  });

  const imgData = canvas.toDataURL('image/png');
  const imgWidth = 595.28; // A4 width in points
  const pageHeight = 800; //841.89; // A4 height in points
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;

  // Add image to PDF and handle page breaks
  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position -= pageHeight;
    doc.addPage();
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }

  doc.rect(0, doc.internal.pageSize.height - 60, doc.internal.pageSize.width, 60, 'F');

  var pdfBase64 = doc.output('datauristring');
  // let url = window.URL.createObjectURL(pdfBase64);
  let a = document.createElement('a');
  a.href = pdfBase64;
  a.download = 'dummy.pdf';
  a.click();

  const pdfBlob = doc.output('blob');
  return new File([pdfBlob], 'CorrectionLetter.pdf', { type: 'application/pdf' });
};

export const getInvoiceBalance = invoice => {
  const balance = invoice.fees.reduce((bal, fee) => {
    return bal + Number(fee.balanceAmount);
  }, 0);
  return balance;
};
