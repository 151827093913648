import React, { FC, Fragment } from 'react';
import { Currency, Header } from './BigScreenTable';
import { format } from 'date-fns';
import styled from 'styled-components';
import { currency } from '../../../../utils';
import { CCFee, PHONE, TABLET } from '../StripeInvoicesTable';
import { getInvoiceBalance } from '../../../../../../Components/InvoiceTool/InvoiceUtil';

const SmallScreenTable: FC<any> = ({ invoices }) => {
  return (
    <Table>
      {invoices.map((invoice,index) => {
        const _total = getInvoiceBalance(invoice);
        return (
          <Fragment key={index}>
            <Row>
              <Header>Invoice</Header>
              <Cell>{invoice.invoiceNumber}</Cell>
            </Row>

            <Row>
              <Header>Invoice Date</Header>
              {invoice && <Cell>{format(new Date(invoice.invoicedAt), 'yyyy-MM-dd')}</Cell>}
            </Row>
            <Row>
              <Header>Amount to Pay</Header>
              <Cell>
                <Currency>{currency(_total * (1 + CCFee))}</Currency>
              </Cell>
            </Row>
            <Separator />
          </Fragment>
        );
      })}
    </Table>
  );
};

export default SmallScreenTable;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Separator = styled.div`
 grid-template-rows: 1/3;
 border: 1px solid grey;
 height: 10px;
`;
const Cell = styled.div`
  background-color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 180px 1fr;
  grid-gap: 2px;
  background-color: rgb(180, 180, 180);
  width: 98%;
  border: 2px solid rgb(180, 180, 180);
  border-radius: 6px;
  @media only screen and (max-width: ${TABLET}) {
    grid-template-columns: 200px 200px 1fr;
  }
  @media only screen and (max-width: ${PHONE}) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;