import { useEffect, useState } from 'react';
import { useGetInvoiceDetailsQuery } from '../../redux/rtkQuery/apiSlice';
import { createInvoiceBody, setInvoiceData } from './InvoiceUtil';
import { useDispatch, useSelector } from 'react-redux';
import { setHasViewClicked, setInvoiceBody, setIsGeneratingInvoice, setOpenInvoicePreview } from '../../redux/slices/feesSlice';
import { RootState } from '../../redux/root';

const useShowInvoicePdf = fees => {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [reimbursableAttachment, setReimbursableAttachment] = useState<string[]>([]);
  const permitFees = useSelector((s: RootState) => s.fees.permitFees);
  const { data: existingInvoice } = useGetInvoiceDetailsQuery(invoiceNumber, { skip: invoiceNumber === '' });
  const dispatch = useDispatch();

  useEffect(() => {
    if (existingInvoice && invoiceNumber !== '' && existingInvoice.invoiceNumber === invoiceNumber) {
      setInvoiceNumber('');
      setInvoiceData(existingInvoice, dispatch);
    }
  }, [existingInvoice, invoiceNumber]);

  useEffect(() => {
    const invoiceFees = fees.length > 0 ? fees : getPermitFeesIds(permitFees);
    if (invoiceFees && invoiceFees.length > 0 && permitFees.length > 0) {
      const _fees = permitFees.filter(pf => invoiceFees.indexOf(pf.id) > -1);
      const attachments = _fees.reduce((strArr, item) => {
        if (item.name === 'Reimbursement' && item.file) {
          strArr.push(item.file);
        }
        return strArr;
      }, []);
      setReimbursableAttachment(attachments);   
      if (existingInvoice) {
        const invoiceBody = createInvoiceBody(existingInvoice.feeDetails);
        dispatch(setInvoiceBody(invoiceBody));
      }
    }
  }, [fees, permitFees, invoiceNumber]);

  const handleInvoiceCellClick = async rowValue => {
    if (rowValue.invoicePdf) {
      const link = document.createElement('a');
      link.href = rowValue.invoicePdf;
      link.download = `${rowValue.fullInvoiceNumber}.pdf`;
      link.click();
      return;
    }
    dispatch(setOpenInvoicePreview(true));
    dispatch(setIsGeneratingInvoice(true));
    dispatch(setHasViewClicked(true));
    setInvoiceNumber(rowValue.fullInvoiceNumber || rowValue.invoiceNumber);
  };

  const getPermitFeesIds = permitFees => {
    return permitFees.reduce((idArr, item) => {
      if (item.invoiceNumber === invoiceNumber) {
        idArr.push(item.id);
      }
      return idArr;
    }, []);
  };
  return {
    handleInvoiceCellClick,
    reimbursableAttachment,
  };
};

export default useShowInvoicePdf;
