import * as React from 'react';
import {
  getActivitiesList,
  getActivitiesStatusList,
  getCustomActivities,
} from '../../api/activities';

import {
  createPermitInspection,
  deletePermitInspection,
  updatePermitInspection,
} from '../../api/inspections';
import { getInspectionTypes } from '../../api/inspectionTypes';
import { getUsers, getUsersByProject } from '../../api/users';
import {
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../../constants';

import PermitInspectionsForm from '../../forms/permitInspectionForm/PermitInspectionsForm';

import useTablePage from '../../hooks/useTablePage';

import { ModalDefaultDraggable } from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';

import Table from '../../organisms/Table';

import { convertTableBoolean } from '../../utils/convertTableValues';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { USER_TYPES_VALUES } from '../../constants/forms';
import { formatDateTime } from '../../utils/formatDate';
import styled from 'styled-components';
import InspectionsInfoBlock from '../../organisms/InspectionsInfoBlock';
import { getPermitByID } from '../../api/permits';
import useUserRole from '../../hooks/useUserRole';
import InspectionDeletePopup from './InspectionDeletePopup';
import { setDeleteError, setShowDeleteMessage } from '../../redux/slices/inspectionsSlice';
import { useDispatch } from 'react-redux';

const InspectionsPage = ({
  projectId,
  permit,
  permitStatus,
  isAddInspection,
  setAddInspection,
  isReadOnly,
  cameFromSearch,
}: Props) => {
  const {
    tableManuallyUpdate,
    updateTable,
    search,
    handleEdit,
    isModalOpen,
    createOrUpdate,
    modalSuccess,
    modalError,
    modalTitle,
    modalErrorTitle,
    errors,
    values,
    handleCloseModalInfo,
    handleCloseModal,
    handleAdd,
    setValues,
  } = useTablePage({
    updateEndpoint: updatePermitInspection,
    createEndpoint: createPermitInspection,
    modalCreateTitle: 'Add Inspection',
    modalUpdateTitle: 'Update Inspection',
  });

  const dispatch = useDispatch();
  const {
    isEmployee,
    isInspector,
  } = useUserRole();

  const [inspectionCount, setInspectionCount] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState<boolean>(
    true,
  );
  const [additionalRowData, setAdditionalRowData] = React.useState(null)

  const [readOnlyInfo, setReadOnlyInfo] = React.useState<any>(
    [],
  );

  const [inspectionDetails, setInspectionDetails] = React.useState<any>(
    {}
  );    

  React.useEffect(() => {
    Promise.all([getCustomActivities({
      permit_id:permit,
      ordering:null,
      event_type:"Inspection",
    })]).then(([inspectionsResponse]) => {
      setInspectionDetails(inspectionsResponse.data.results[0]);
    })
  }, [])

  React.useEffect(() => {
    if (isAddInspection) {
      handleAdd();
    }
  }, [isAddInspection]);

  React.useEffect(() => {
    updateTable();
    if (modalSuccess && !modalError) {
      setAddInspection(false);
    }
  }, [modalSuccess, modalError]);

  const closeInfoModal = () => {
    updateTable();
    handleCloseModalInfo();
  };

  const columns = column => {
    if (
      [
        'permit',
        'info',
        'information',
        'trades',
        'isEmergency',
        'period',
        'isCompleted',
        'report',
        'isDeleted',
        'lastComment',
        'permitName',
        'permitNumber',
        'projectName',
        'projectJurisdiction',
        'projectAddress',
        'contactFirstName',
        'contactLastName',
        'companyName',
        'assignedToFirstName',
        'assignedToLastName',
        'inspectorFirstName',
        'inspectorLastName',
        'activityTypeName',
        'activityTitleName',
        'statusName',
        'projectId',
        'managerFirstName',
        'managerLastName',
        'phoneNumber',
        'cellPhone',
        'contactId',
        'type',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'visibleFileName'
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'title':
        return 'Activity Title';
      case 'status':
        return 'Activity Status';
      case 'id':
        return 'Inspection Number';
      case 'inspectionType':
        return 'Inspection Type';
      case 'inspectionDate':
        return 'Inspection Date';
      case 'inspector':
        return 'Inspector';
      case 'contact':
        return 'Activity Contact';
      case 'followUp':
        return 'Follow Up Date';
      case 'assignedTo':
        return 'Assigned To';
      case 'visibleFilename':
        return 'Inspection Report';
      case 'createdAt':
        return 'Date Created';
      case 'completedAt':
        return 'Date Completed'
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'title',
    'status',
    'id',
    'inspectionType',
    'inspectionDate',
    'inspector',
    'contact',
    'followUp',
    'assignedTo',
    'createdAt',
    'createdBy',
    'completedAt',
    'completedBy'
  ];

  const onDelete = async (arg) => {   
    const error = arg.statusName !== "Not Scheduled";
    if(error){
      dispatch(setDeleteError(error));
      dispatch(setShowDeleteMessage(true));
      return;
    }
    

    const result = await deletePermitInspection({id: arg.id});
    if(result?.data?.success){
      dispatch(setShowDeleteMessage(true));
      updateTable();
    }
  }
  const modifiedGetPermitInspections = params =>
    Promise.all([
      getCustomActivities({
        ...params,
        // permit,
        permit_id:permit,
        sort_by: params?.ordering,
        ordering:null,
        event_type:"Inspection",
      }),
    ])
      .then(
        ([
          inspectionsResponse,
        ]) => {          
          setInspectionCount(inspectionsResponse?.data?.highestCount)
          setIsLoading(false);
          const informationData = inspectionsResponse.data.results.map(
            el => ({
              id: el.id,
              information: el.information,
            }),
          );
          setAdditionalRowData({
            commentData: [],
            informationData,
            isDailyInspection: true,
          })
            return {
            ...inspectionsResponse,
            data: {
              ...inspectionsResponse.data,
              results: inspectionsResponse.data.results.map(
                inspection => {
                    return {
                      ...inspection,
                      title: inspection?.activityTitleName,
                      status: inspection?.statusName,
                      id: inspection?.id,
                      inspectionType: inspection?.activityTypeName,
                      inspector: inspection?.inspectorFirstName
                        ? `${inspection?.inspectorFirstName} ${inspection?.inspectorLastName}`
                        : '',
                      contact: `${inspection?.contactFirstName || ""} ${
                        inspection?.contactLastName || ""
                      }${
                        inspection?.companyName ||
                        inspection?.parentCompany||""
                          ? ` - ${
                              inspection?.companyName ||
                              inspection?.parentCompany||""
                            }`
                          : ''
                      }`,
                      assignedTo:`${inspection?.assignedToFirstName||""} ${inspection?.assignedToLastName||""}`,
                      inspectionDate: inspection?.inspectionDate
                        ? formatDateTime(
                            inspection.inspectionDate,
                            DEFAULT_DATE_FORMAT,
                          )
                        : '',
                      followUp:
                        inspection?.followUp
                        // inspection?.followUp !== '01/01/2050'
                          ? formatDateTime(
                              inspection.followUp,
                              DEFAULT_DATE_FORMAT,
                            )
                          : '',
                      createdAt:
                      inspection?.createdAt
                        ? formatDateTime(
                            inspection.createdAt,
                            DEFAULT_DATE_FORMAT,
                          )
                        : '',
                        isDeleted: cameFromSearch,
                    };

                },
              ),
            },
          };
        },
      )
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {});

  const handleCloseInspectionModal = () => {
    if (!modalError && !modalSuccess) {
      setAddInspection(false);
      handleCloseModal();
    }
  };

  React.useEffect(() => {    
    setReadOnlyInfo([
      {
        label: 'Project Name',
        value: inspectionDetails?.projectName,
      },
      {
        label: 'Permit Name',
        value: inspectionDetails?.permitName,
      },
      {
        label: 'Permit Address',
        value: inspectionDetails?.projectAddress,
      },
      {
        label: 'Permit Number',
        value: inspectionDetails?.permitNumber,
      },
    ]);
  }, [inspectionDetails])

  return (
    <>      
       {!isLoading && (
          <InpectionDetailsBlock>   
          {isReadOnly ?                
            <InspectionsInfoBlock info={readOnlyInfo} /> : ''}
            <h3>
              Total Inspections:{' '}
              <StyledNumber>
                {inspectionCount}
              </StyledNumber>
            </h3>
        </InpectionDetailsBlock>
      )}
        <InspectionDeletePopup />
        <Table
          customSearch
          initialUseSortBy
          permitInspectionTable
          searchQuery={search}
          columns={columns}
          columnsOrder={columnsOrder}
          colorSchema
          rows={modifiedGetPermitInspections}
          onEdit={isReadOnly? null : handleEdit}
          onDelete={onDelete}
          onConfigure={null}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}          
          additionalRow={isInspector ? additionalRowData : null}
          italic
          isPermitActivitiesPage={true}
        />
        {isModalOpen && (         
          <ModalDefaultDraggable
            title={modalTitle}
            onClose={handleCloseInspectionModal}
          >
            <PermitInspectionsForm
              projectId={projectId}
              onSubmit={createOrUpdate}
              initialValues={values}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
              permitStatus={permitStatus}
              isReadOnly={false}
              setInitialValues={setValues}
              updateTable={updateTable}
            />
          </ModalDefaultDraggable>         
        )}
        {(modalSuccess || modalError) && (
          <ModalInfo
            timeToClose={5000}
            isError={modalError}
            onClose={closeInfoModal}
            errorMsg={modalErrorTitle}
          />
        )}
    </>
  );
};

interface Props {
  permit: number;
  isAddInspection: boolean;
  setAddInspection: (data: boolean) => void;
  isReadOnly: boolean;
}
const StyledNumber = styled.span`
  color: var(--primary);
`;

const InpectionDetailsBlock = styled.div`
`;
export default InspectionsPage;
