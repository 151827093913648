import React, { useEffect, useState } from 'react';
import { Pagination, TableHeader, TableWrapper, commonFilterMeta } from '../../Common/Table/types';
import { updatePagination } from '../../../utils';
import useFilterActions from '../../../../../pages/Responsive/useFilterActions';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import Table from '../../Common/Table/Table';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setRowsCount } from '../../../../../redux/slices/paginationSlice';
import { setSelectedPermit } from '../../../../../redux/slices/inspectionsSlice';
import { useGetAllPermitInspectionsQuery } from '../../../../../redux/rtkQuery/apiSlice';

export const headers: TableHeader[] = [
  {
    label: 'Project Name/Permit Name',
    sortingKey: 'namePermitName',
    itemName: 'namePermitName',
  },
  {
    label: 'Address',
    sortingKey: 'address',
    itemName: 'address',
  },
  {
    label: 'Status',
    sortingKey: 'status',
    itemName: 'status',
  },
  {
    label: 'Quantity',
    itemName: 'quantity',
    theKey: 'quantity',
    hideFilter: true,
  },
  { label: 'Detail', hideFilter: true, itemName: '' },
];
const InspectionsTable = () => {
  useGetNewProjectData();
  const dispatch = useDispatch();  
  const permitInspectionsList = useSelector((s: RootState) => s.inspections.permitInspectionsList);
  const filteredRows = useFilterActions(permitInspectionsList);
  const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);
  const [visiblePermits, setVisiblePermits] = useState(filteredRows);
  const [permit, setPermit] = useState<number>(-1);
  const show = useSelector((s:RootState) => s.general.showDrawerContent);
  const {data } = useGetAllPermitInspectionsQuery(permit,{skip: permit === -1})
 
  useEffect(() => {
    const _permits = updatePagination(pageIndex, pageSize, filteredRows);

    dispatch(setRowsCount(filteredRows.length));
    setVisiblePermits(_permits);
  }, [pageIndex, pageSize, filteredRows, permitInspectionsList]);

  useEffect(() => {
    if(data){    
      const selected = permitInspectionsList.find(r => r.permitId === permit); 
      const _selected = {
        ...selected,
        inspections: data,
      }      
      //@ts-ignore
      dispatch(setSelectedPermit(_selected));     
    }
  }, [data, show])
  const onInspectionSelected = (item) => {    
    setPermit(item.permitId);
    dispatch(setSelectedPermit(item));
  }
  return (
    <TableWrapper>
      <Table
        collection={visiblePermits}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 175px 90px 90px"
        setSelectedItemInSlice={onInspectionSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default InspectionsTable;
