import React, { useEffect, useState } from 'react';
import { CheckboxWithLabel, InputBaseContainer } from '../../molecules';
import { Controller } from 'react-hook-form';
import { getPermitVoidStatus } from '../../api/fees';
import { useParams } from 'react-router';

const StatusCheckboxes = ({initialValues,control,errors,labels,cameFromSearch,isReadOnly}) => {
  const { permitId } = useParams();
  const [blockVoid, setBlockVoid] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const params = {
        permit: permitId,
        feeId: initialValues.id
      }
      const result = await getPermitVoidStatus(params);
      if(result && result.data && result.data.length > 0 ) {
        const partiallyPaid = result.data[0].partiallyPaid;
        if(partiallyPaid){
          setBlockVoid(true);
        }
      } 
    }
    load();
  }, [])

  const disableVoid = () => {
    if(blockVoid){
      return true;
    }
    return !initialValues?.invoiceId
           || isReadOnly 
           || initialValues?.isVoid // EA-361
           || initialValues?.status === 'Paid' 
           || cameFromSearch ||
            initialValues?.status === 'Paid by Another Method'
  }


    return (
        <div>
            <InputBaseContainer>
              <Controller
                name="isPaid"
                control={control}
                valueName="paid"
                defaultValue={initialValues?.status === "Paid"}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.isPaid}
                    label={labels.isPaid}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={initialValues || isReadOnly
                    }
                  />
                )}
              />
            </InputBaseContainer>
            {/* Adding the paid with other method checkbox */}
            <InputBaseContainer>
              <Controller
                name="paidManually"
                control={control}
                valueName="paidManually"
                defaultValue={initialValues?.status === 'Paid by Another Method'}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.paidManually}
                    label={labels.paidManually}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}                    
                    disabled={(
                      !initialValues?.invoiceId || initialValues?.invoiceNumber?.startsWith('INV') ||
                      initialValues?.status?.toLowerCase().includes("paid") || initialValues?.isVoid || isReadOnly) && !(initialValues?.status === 'Invoiced in QB')
                    }
                  />
                )}
              />
            </InputBaseContainer>
                      <InputBaseContainer>
              <Controller
                name="isVoid"
                control={control}
                valueName="checked"
                defaultValue={initialValues?.isVoid}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.void}
                    label={labels.void}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={disableVoid()}                    
                  />
                )}
              />
            </InputBaseContainer> 
        </div>
    );
};

export default StatusCheckboxes;