import { api } from './api';

import { PaginatedList, PaginatedRequest } from '../types';
import { TradeType } from './trades';
import { JurisdictionType } from './jurisdiction';
import { FranchiseType } from './franchise';
import { getAccessToken } from '../auth';

export const getInspectionTypes = (
  params: PaginatedRequest,
) =>
  api.request<InspectionTypesList>({
    method: 'GET',
    url: '/inspection-types/complex-views/with-related/',
    params,
  });

export const getInspectionTypesCustom = (id) =>
  api.request<InspectionTypesList>({
    method: 'GET',
    url: `/inspection-types/jurisdiction-inspection-types/?jurisdiction_id=${id}`,
  });

export const getInspectionEditTypes = (
  params: PaginatedRequest,
) =>
  api.request<InspectionTypesList>({
    method: 'GET',
    url: '/inspection-types/',
    params,
  });
// ##################
// EA-54
//  Upload inspection report api call 
// mritunjoy 
export const uploadInspectionReport = (data: any) => {

  const formData = new FormData();

  formData.append('report', data.report);
  formData.append('title', data.title);
  formData.append('inspection_type', data.inspection_type);
  formData.append('status', data.status);

  return api.put(
    `/inspections/${data.id}/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

// Creating delete Inspection report API function
// EA-62
// mritunjoy
export const deleteInspectionReport = id => {
  return api.request({
    method: 'POST',
    url: '/inspections/delete-report',
    data: { id },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};


// Download inspection report 
// mritunjoy
export const downloadInspectionReport = id => {
  // making it formData because from the backend the id is taking it as formData
  const formData = new FormData();
  formData.append('documents', id)
  return api.post(
    `/inspections/download/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );


};

// Getting the inspection type byt id
export const getInspectionTypeByID = (id: number) =>
  api.request<InspectionType>({
    method: 'GET',
    url: `/inspection-types/${id}/`,
  });

export const createInspectionType = (
  data: InspectionType,
) =>
  api.request<InspectionType>({
    method: 'POST',
    url: '/inspection-types/',
    data,
  });

export const updateInspectionType = (
  data: InspectionType,
) =>
  api.request<InspectionType>({
    method: 'PATCH',
    url: `/inspection-types/${data.id}/`,
    data,
  });

export const deleteInspectionType = (id: number) =>
  api.request<InspectionType>({
    method: 'DELETE',
    url: `/inspection-types/${id}/`,
  });

type InspectionTypesList = PaginatedList<
  InspectionTableType
>;

export interface InspectionTableType {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  jurisdictionId: number;
  jurisdictionName: string;
  name: string;
  tradeId: number;
  tradeName: string;
}

export interface InspectionType {
  id: number;
  name: string;
  isActive: boolean;
  isDefault?: boolean;
  isDeleted: boolean;
  trades: number[] | TradeType[];
  jurisdiction:
  | number
  | JurisdictionType[]
  | JurisdictionType;
  jurisdictions:
  | number
  | JurisdictionType[]
  | JurisdictionType;
  franchise: number[] | string | FranchiseType[];
}

export type CommentType = {
  createdAt: string;
  file: any;
  id: number;
  text: string;
  username: string;
  visibleFilename: string;
}

export type DailyInspectionType = {
  contactDetails: string;
  contactName: string;
  franchiseName: string;
  id:number;
  permitId?: number;
  information: string;
  inspectionDate: string;
  inspectionTypeName: string;
  inspectorName: string;
  jurisdiction: string;
  lastComment: CommentType;
  period: number;
  permitName: string;
  permitNumber: string;
  projectAddress: string;
  projectManager: string;
  projectName: string;
}